import React from 'react';
import PropTypes from 'prop-types';

import Label, { LabelTypography, LabelColors, LabelStringSet } from '../Label';
import IconButton from '../IconButton';

import './index.scss';

export default function ChannelHeader({ title, iconButton }) {
  return (
    <div className="sendbird-channel-header">
      <div className="sendbird-channel-header__title">
        <Label type={LabelTypography.H_2} color={LabelColors.ONBACKGROUND_1}>
          {title || LabelStringSet.CHANNEL_LIST__TITLE}
        </Label>
      </div>
      <div className="sendbird-channel-header__right-icon">
        {iconButton}
      </div>
    </div>
  );
}

ChannelHeader.propTypes = {
  title: PropTypes.string,
  iconButton: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.instanceOf(IconButton),
  ]),
};

ChannelHeader.defaultProps = {
  title: '',
  iconButton: null,
};
